function loadCSS() {
  console.log('css')
  import("../sass/7-bundles/index.scss");
}

function loadPreloader() {
  import('../scripts/preloader.js')
}

function loadScroll() {
  import('../scripts/smoothScroll.js')
}

function loadHeroSlider() {
  import('../heroslider/heroslider.js')
}

function loadMap() {
  console.log('map')
  import('../scripts/map.js')
}

function loadNav() {
  console.log('nav')
  import('../scripts/nav.js')
}

function loadOffers() {
  import('../scripts/homeAnim.js')
}

function loadMore() {
  import('../scripts/more.js')
}

function loadExplore() {
  import('../scripts/explore.js')
}

// *************
// loadCSS()
// loadPreloader()

window.addEventListener('load', async function loadModules() {
  await loadCSS()
  await loadPreloader()
  await loadScroll()
  await loadHeroSlider()
  await loadNav()
  await loadMap()
  await loadMore()
  await loadOffers()
  await loadExplore()
})